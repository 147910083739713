const API_URL = "https://api.checkup.tcup.co.uk";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_ARy8iCYPF",
        APP_CLIENT_ID: "2a67i0c6frn2l90bel9aclmf51",
        IDENTITY_POOL_ID: "eu-west-2:d8eeef9d-0cbc-4cd4-93fd-a29d930e3fd8"
    },
    s3: {
        BUCKET: "tcup-prod-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        }
    }
};